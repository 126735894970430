import { render, staticRenderFns } from "./DriverSubmittedOrders.vue?vue&type=template&id=aad4d596"
import script from "./DriverSubmittedOrders.vue?vue&type=script&lang=js"
export * from "./DriverSubmittedOrders.vue?vue&type=script&lang=js"
import style0 from "./DriverSubmittedOrders.vue?vue&type=style&index=0&id=aad4d596&prod&lang=scss&scopped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports